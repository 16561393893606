import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/community-portal/community-portal/src/modules/layouts/mdx_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const CTA = makeShortcode("CTA");
const Checklist = makeShortcode("Checklist");
const Chocolate = makeShortcode("Chocolate");
const Box = makeShortcode("Box");
const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Take Notes`}</h1>
    <h3>{`Earn Dai by Taking Notes at Weekly Governance Calls`}</h3>
    <CTA mdxType="CTA">
      <h3>{`Express Interest`}</h3>
      <p>{`To become a part of the working group that produces these summaries, join the Maker Chat. Communicate your interest in the `}{`#`}{`community-development channel.`}</p>
      <p><a parentName="p" {...{
          "href": "https://chat.makerdao.com/channel/community-development"
        }}>{`Community Chat`}</a></p>
    </CTA>
    <h2>{`Expectations`}</h2>
    <Checklist mdxType="Checklist">
      <p>{`Be available for as many calls as possible, `}<strong parentName="p">{`every Thursday`}</strong>{` at 4:00 PM UTC.`}</p>
      <p>{`Have an attention to detail. Notes need to be accurate and formatted consistently.`}</p>
      <p>{`Have a good understanding of Maker governance. Check out the `}<a parentName="p" {...{
          "href": "/learn/governance/"
        }}>{`governance section.`}</a></p>
    </Checklist>
    <h2>{`Suggested Reading`}</h2>
    <Chocolate mdxType="Chocolate">
      <Box mdxType="Box">
        <h3>{`Template`}</h3>
        <p>{`Follow this note-taking template for taking governance notes to ensure consistency. See `}<Link to="https://community-development.makerdao.com/governance/governance-and-risk-meetings/summaries" mdxType="Link">{` summaries of past meetings`}</Link>{` to get an idea of what finished notes should look like.`}</p>
        <p><a parentName="p" {...{
            "href": "/contribute/governance_and_risk_meetings/take-notes/call-template-governance-and-risk-meetings/"
          }}>{`Note-Taking Template`}</a></p>
      </Box>
      <Box mdxType="Box">
        <h3>{`Process`}</h3>
        <p>{`See the note-taking process for taking accurate and thorough notes at governance calls. Find out how a writer and reviewer team can work together on the note-taking process.`}</p>
        <p><a parentName="p" {...{
            "href": "/contribute/governance_and_risk_meetings/take-notes/note-taking-team/"
          }}>{`Note-Taking Team Process`}</a></p>
      </Box>
      <Box mdxType="Box">
        <h3>{`Formatting Guide`}</h3>
        <p>{`Read before volunteering to make sure you’re comfortable with the level of thoroughness we expect. It’s also a good refresher before you take notes.`}</p>
        <p><a parentName="p" {...{
            "href": "/contribute/governance_and_risk_meetings/take-notes/formatting-guide/"
          }}>{`Note-Taking Formatting Guide`}</a></p>
      </Box>
    </Chocolate>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      